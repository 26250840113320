import React from 'react';
import useTextRevealAnimation from '../../../hooks/useTextRevealAnimation';
import './ComingSoonFooter.scss';

const ComingSoonFooter = () => {
    const footerRef = useTextRevealAnimation('.u-transform-down');

    return (
        <footer className="coming-soon-footer" ref={footerRef}>
            <div className="coming-soon-footer__inner">

                <div className="coming-soon-footer__content">
                    <p className="coming-soon-footer__sentence">
                        <div className="coming-soon-footer__phrase-container u-overflow-hidden">
                            <span className="coming-soon-footer__phrase u-transform-down">Currently —</span>
                        </div>
                        <div className="coming-soon-footer__phrase-container u-overflow-hidden">
                            <span className="coming-soon-footer__phrase u-transform-down u-emphasize-text">working on our website.</span>
                        </div>
                    </p>
                </div>

                <div className="coming-soon-footer__contact">
                    <div className="coming-soon-footer__contact-title-container u-overflow-hidden">
                        <h4 className="coming-soon-footer__contact-title u-transform-down">Any inquiries ‽</h4>
                    </div>
                    <dl className="coming-soon-footer__contact-list">
                        <div className="coming-soon-footer__contact-item">
                            <dt className="coming-soon-footer__contact-term">
                                <div className="coming-soon-footer__contact-term-box u-overflow-hidden">
                                    <span className="u-transform-down">Tel</span>
                                </div>
                            </dt>
                            <dd className="coming-soon-footer__contact-detail">
                                <div className="coming-soon-footer__contact-detail-box u-overflow-hidden">
                                  
                                    <a href='tel:+31617716610' className="coming-soon-footer__contact-link">
                                        <span className="u-transform-down">+31 6 17 71 66 10</span>
                                    </a>
                                </div>
                            </dd>
                        </div>

                        <div className="coming-soon-footer__contact-item">
                            <dt className="coming-soon-footer__contact-term">
                                <div className="coming-soon-footer__contact-term-box u-overflow-hidden">
                                    <span className="u-transform-down">Mail</span>
                                </div>
                            </dt>
                            <dd className="coming-soon-footer__contact-detail">
                                <div className="coming-soon-footer__contact-detail-box u-overflow-hidden">
                                    <a href='mailto:hi@example.nl' className="coming-soon-footer__contact-link">
                                        <span className="u-transform-down">connect@mektebmedia.nl</span>
                                    </a>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </footer >


    );
};

export default ComingSoonFooter;
